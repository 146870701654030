import React from "react";
import ReactGA from "react-ga";
import Landscape from "./pages/Landscape";
import Mobile from "./pages/Mobile";
import {
  useMobile,
  useLandscape,
  useDeviceType,
} from "./services/useWindowDimensions.js";
import "./assets/css/App.css";

const TRACKING_ID = "UA-246011606-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const commonProps = {
    isMobile: useMobile(),
    isLandscape: useLandscape(),
    deviceType: useDeviceType(),
  };

  return (
    <div className="App">
      {commonProps.isMobile ? (
        <Mobile {...commonProps} />
      ) : (
        <Landscape {...commonProps} />
      )}
    </div>
  );
};

export default App;

import { H1, H2, H3, H4, H5, H6, H7 } from "../../exports/Images";
import { N1, N2, N3, N4, N5, N6, N7, N8 } from "../../exports/Images";
import { G1, G2, G3, G4, G5, G6 } from "../../exports/Images";
import { S1, S2, S3, S4 } from "../../exports/Images";

export const HoneyMoonHonolulu = [
  {
    type: "h2",
    text: "Honeymoon Honolulu",
  },
  {
    type: "h3",
    text: "An App Concept for Putting Newlywed Tourism Back into Local Hands.",
  },
  {
    type: "block",
    text: [
      "Team: Lauren Phillips",
      "Tools: Figma, Trello, Google Suite",
      "Timeline: 2 Weeks - December 1 - 15, 2021",
    ],
  },
  {
    type: "img",
    img: H1,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h2",
    text: "Project Overview",
  },
  {
    type: "p",
    text: "Honeymoon Honolulu is an app concept I made that aims to allow newlywed users to stimulate Honolulu’s economy and help local business owners create opportunities for themselves at the same time. It recommends local businesses that are impactful and relevant to users, and enables honeymooners to have the best trip for themselves and the community of Hawai'i.",
  },
  {
    type: "h2",
    text: "Problem Statement",
  },
  {
    type: "p",
    text: "Growing up in Hawai'i and working in the tourism industry, I’ve seen the impact that foreign large businesses have had on the island, driving the cost of living up and displacing locals who can’t make enough to stay. The COVID-19 pandemic exacerbated this as many local businesses couldn’t survive the lack of tourists essential to a tourism-dependent economy like Hawai'i’s.",
  },
  {
    type: "p",
    text: "Because local businesses are in competition with bigger non-local companies and corporations, this leads to:",
  },
  {
    type: "ol",
    text: [
      "Biased visibility as less clients means less reviews and therefore less traffic",
      "Less profits as more commission ends up being taken by second and third parties",
    ],
  },
  {
    type: "h2",
    text: "Goals",
  },
  {
    type: "p",
    text: "User Goal:",
  },
  {
    type: "u",
    text: "I want to create a personable platform that allows honeymooners to plan an ethical trip without information overload, bias, and skepticism; all while optimizing usability and encouraging users to support local.",
  },
  {
    type: "p",
    text: "Business Goal:",
  },
  {
    type: "u",
    text: "I want to ensure businesses don’t have to pay for page views, take a profit cut, or give commission just to put themselves out there. The primary metric I’d be looking at is direct bookings on their own websites. ",
  },
  {
    type: "break",
    style: { padding: "10px" },
  },
  {
    type: "h2",
    text: "Design Process",
  },
  {
    type: "p",
    text: "During the ideation process, I initially explored the potential of how tourism could benefit locals rather than hurting them with the help of an app catered specifically for local businesses in Honolulu.",
  },
  {
    type: "p",
    text: "I aimed to identify what issues local business owners face when accommodating tourists, and create a solution that joins the efficiency and simplicity of a honeymoon planning app, with the intention of supporting local. I identified an unmet need in the Hawai'i Tourism market and developed an idea for a dynamic guide promoting local businesses to couples Honeymooning in Honolulu.",
  },
  {
    type: "img",
    img: H2,
  },
  {
    type: "h3",
    text: "Category Highlights",
  },
  {
    type: "p",
    text: "Using the results of an initial user survey asking couples what outcomes they’d want from their honeymoon experience, I created 4 categories for users to select from: Play, Dine, Explore, and Connect.",
  },
  {
    type: "p",
    text: "These categories make it easier for users to browse through the many choices available to them by identifying their intentions and allowing each business or experience to have its own opportunity to be highlighted, without distraction and information overload. ",
  },
  {
    type: "img",
    img: H3,
  },
  {
    type: "h3",
    text: "Recommendations + Lists",
  },
  {
    type: "p",
    text: "I designed a feature that highlights local businesses and allows users to see recommended ideas for activities at a glance. Paired with a bookmarking feature and an external link to the company’s website, Honeymoon Honolulu allows users to browse and keep track of their interests without complicating the process so profits can go directly to business owners.",
  },
  {
    type: "img",
    img: H4,
  },
  {
    type: "p",
    text: "In talking to a friend who’s also from Hawai'i I later recalled that a lot of locals, including myself, have their own lists of must-try activities or restaurants for friends and family visiting the islands. I realized a lot of value can come from recommendations by locals as users find them trustworthy, and included a section to feature curated lists created by locals themselves. ",
  },
  {
    type: "img",
    img: H5,
  },
  {
    type: "h3",
    text: "Itinerary",
  },
  {
    type: "p",
    text: "Originally I had not included an itinerary feature on the app as I considered it solely an exploratory tool. However, I received feedback from user testing that indicated users would benefit from having the app also keep track of the activities they found and booked through the app.",
  },
  {
    type: "p",
    text: "By allowing users to forward their itinerary to the app, they are able to keep track of their plans while also finding more ideas. The itinerary feature encourages couples to revisit the app throughout their honeymoon, so they can continue to see recommendations for their on-the-fly plans. ",
  },
  {
    type: "custom",
    text: [
      {
        type: "normal",
        text: "By allowing users to forward their itinerary to the app, they are able to keep track of their plans while also finding more ideas. The itinerary feature",
      },
      {
        type: "b",
        text: " encourages couples to revisit the app throughout their honeymoon",
      },
      {
        type: "normal",
        text: ", so they can continue to see recommendations for their on-the-fly plans.",
      },
    ],
  },
  {
    type: "img",
    img: H6,
  },
  {
    type: "h2",
    text: "Results",
  },
  {
    type: "p",
    text: "While Honeymoon Honolulu is merely a conceptual app for now, some hypothetical business success metrics I’d pay attention to would be:",
  },
  {
    type: "ol",
    text: [
      "Increased conversion rates and direct bookings made through the app",
      "Increased traffic to local businesses’ sites",
      "User and customer growth in terms of both accounts and businesses, respectively",
    ],
  },
  {
    type: "p",
    text: "Ideally Honeymoon Honolulu would encourage users to honeymoon responsibly and ethically - so residents can continue to call the islands their home and couples can continue to visit a thriving, more self-sufficient Hawaii.",
  },
  {
    type: "img",
    img: H7,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
];

export const Narika = [
  {
    type: "h2",
    text: "Narika",
  },
  {
    type: "h3",
    text: "Narika - Redesigning a Non-Profit Site for Survivors of Domestic Abuse",
  },
  {
    type: "block",
    text: [
      "Team: Lauren Phillips, Renu Vaswani, Taylor Thompson, Sarah Posner, Katya Hasett",
      "Tools: Figma, Trello, Google Suite, UXMetrics, SurveyMonkey",
      "Timeline: 2 Weeks - August 15 - 30, 2021",
    ],
  },
  {
    type: "img",
    img: N1,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h2",
    text: "Project Overview",
  },
  {
    type: "p",
    text: "Founded by immigrant women and serving the Bay Area since 1992, Narika is a non-profit organization that assists victims of domestic violence and trafficking. Due to COVID-19 stay at home orders, domestic violence rates increased by 8.1% and it became increasingly important for users in crisis to be able to find help, which is why Narika asked us to redesign their webpage. ",
  },
  {
    type: "p",
    text: "We met with Narika’s stakeholders to identify their biggest concerns. They wanted to highlight the impact of the work Narika does, but struggled with balancing what potential clients may want to see vs. what their allies and partner organizations want to see. They also expressed the importance of accessibility when it comes to helpline info, the contact button, and the donate button. ",
  },
  {
    type: "p",
    text: "The redesign of the homepage allows users in crisis to find the resources they need immediately, while still providing information to sponsors and prospective donors/volunteers. These changes also enable Narika to convey their services more accurately and succinctly, allowing for more women to be helped and increased support in the form of volunteers and donations. ",
  },
  {
    type: "img",
    img: N2,
  },
  {
    type: "h2",
    text: "Problem Statement",
  },
  {
    type: "p",
    text: "Narika users don’t feel confident about their decision to reach out or get involved. This is due to the current lack of design consistency and overload of information that is unorganized, unprioritized, and mostly catered to those sponsoring the organization. ",
  },
  {
    type: "p",
    text: "Providing or receiving help through the site requires significant time and mental evaluation, which poses safety hazards for survivors visiting the site. This ultimately results in reduced outreach and a loss of trust in Narika’s mission.",
  },
  {
    type: "h2",
    text: "Goals",
  },
  {
    type: "p",
    text: "Because users are struggling to identify indicators of trust and take fast action on the homepage, this leads to:",
  },
  {
    type: "ol",
    text: [
      "Increased bounce rate and reduction in action taken by both volunteers and survivors",
      "Distraction from Narika’s mission, hurting their potential impact and outreach",
    ],
  },
  {
    type: "p",
    text: "User Goal:",
  },
  {
    type: "u",
    text: "We want to encourage users in crisis to quickly and easily evaluate and decide to receive help. We also want to allow other users to get the information they need to donate either their time or money, while optimizing usability and credibility.",
  },
  {
    type: "p",
    text: "Business Goal:",
  },
  {
    type: "u",
    text: "We want to maximize Narika’s outreach by making their site more intuitive and a safer place for users in crisis to use. We also want to encourage more clicks on the call to actions such as donating and calling their helpline. The metrics we would be looking at are bounce rates and changes in the amount of donations.",
  },
  {
    type: "break",
    style: { padding: "10px" },
  },
  {
    type: "h2",
    text: "Design Process",
  },
  {
    type: "p",
    text: "During our initial research, we performed competitor benchmarking to explore the expected overall theme and feeling of websites such as Narika’s. ",
  },
  {
    type: "p",
    text: "We found that the site should convey a message of hope, a focused and inspiring mission, and legitimacy/safety. Along with this, we needed to create a solution that is intuitive and easy to use, while also taking into account all the different types of users who visit their site.",
  },
  {
    type: "img",
    img: N3,
  },
  {
    type: "h3",
    text: "Navigation",
  },
  {
    type: "p",
    text: "Using the insights from our user interviews, we found that almost all users had issues with the navigation bar, site organization, and easy access to content. ",
  },
  {
    type: "p",
    text: "We addressed these concerns by reorganizing the navigation to be simple and intuitive, dividing each category by user needs and information. We kept the call to action buttons the same but reworded them to be clearer, allowing users to navigate Narika’s site with ease. ",
  },
  {
    type: "p",
    text: "We then added a secondary menu to provide space for items such as contact, news, blog, and language selection. This enables easy access to important features that may not necessarily fit in the dropdown menu.",
  },
  {
    type: "img",
    img: N4,
  },
  {
    type: "h3",
    text: "Safety + Help",
  },
  {
    type: "p",
    text: "During our stakeholder interviews, we learned that survivors of domestic abuse or trafficking are not able to take their time browsing as they may face consequences if they are caught seeking help. ",
  },
  {
    type: "p",
    text: "Knowing this, we aimed to create a safer experience by making changes to the safety exit button. This button functions as an escape route for users in crisis to immediately reroute to a neutral site, which in our case is Google. We moved it to a more prominent and easily accessible spot on both mobile and desktop to allow for a quick exit in case of emergency.",
  },
  {
    type: "p",
    text: "We also implemented a chat bot, Nari, to help users not only receive quicker access to information they may be seeking, but to also allow users in crisis a way to communicate in situations where having a phone conversation may pose a safety hazard. ",
  },
  {
    type: "img",
    img: N5,
  },
  {
    type: "h3",
    text: "Events",
  },
  {
    type: "p",
    text: "In terms of calendars and events, our heuristic evaluation on the current site revealed that Narika’s way of displaying events was an inefficient use of space and did not allow users to view event information easily. ",
  },
  {
    type: "p",
    text: "We replaced the event calendar with a section for events which are listed alongside a short description and relevant photo to provide more information at a glance.",
  },
  {
    type: "img",
    img: N6,
  },
  {
    type: "h3",
    text: "Donations",
  },
  {
    type: "p",
    text: "While evaluating the current site, we found that clicking the donation button brings users to a completely separate screen in order to make their donation.",
  },
  {
    type: "p",
    text: "After discussions and research with the team, we concluded that the donate button should instead bring up a pop-up when clicked, ensuring the donation experience does not disrupt access to the rest of the site. ",
  },
  {
    type: "img",
    img: N7,
  },
  {
    type: "h2",
    text: "Results",
  },
  {
    type: "p",
    text: "Although these changes have yet to be implemented on Narika’s site, some hypothetical business metrics I’d pay attention to would be:",
  },
  {
    type: "ol",
    text: [
      "Decreased bounce rates",
      "Increased conversion rates and donations from users",
      "Increased user engagement",
    ],
  },
  {
    type: "p",
    text: "Once implemented, these changes would encourage more survivors to reach out for help and foster feelings of trust and safety. They would also allow other users to easily see the positive impact Narika is making and encourage them to contribute to the mission themselves.",
  },
  {
    type: "img",
    img: N8,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
];

export const Gameboy = [
  {
    type: "h2",
    text: "Gameboy Portfolio",
  },
  {
    type: "h3",
    text: "Gameboy Portfolio - A Neo-Stalgia Website Concept",
  },
  {
    type: "block",
    text: [
      "Team: Lauren Phillips",
      "Tools: Figma, Trello, Google Suite, UXMetrics, SurveyMonkey",
      "Timeline: 2 Weeks - October 10-25, 2021",
    ],
  },
  {
    type: "img",
    img: G1,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h2",
    text: "Project Overview",
  },
  {
    type: "p",
    text: "This Gameboy-inspired site is a concept I originally created for use as my portfolio in order to catch the attention of recruiters and potential employers, as well as demonstrate my ability to not only create conventional websites but unique and fun ones as well. I aimed to design a site that reflects my personality and evokes nostalgia while also showcasing the case studies I’ve worked on.",
  },
  {
    type: "h2",
    text: "Problem Statement",
  },
  {
    type: "p",
    text: "While browsing different portfolios and searching for inspiration, I found that a lot of portfolios followed cookie-cutter formats and looked very similar to each other. I’ve always sought out ways to differentiate myself from others, and knew I wanted to do something out-of-the-box when it came to creating my own portfolio. ",
  },
  {
    type: "p",
    text: "Because UX/UI design is a new yet highly competitive field, a portfolio that doesn’t stand out can lead to:",
  },
  {
    type: "ol",
    text: [
      "Not making a lasting impression to recruiters and potential employers",
      "Poor demonstration of creativity in a field where it is of utmost importance",
    ],
  },
  {
    type: "h2",
    text: "Goals",
  },
  {
    type: "p",
    text: "User Goal:",
  },
  {
    type: "u",
    text: "I want to create a unique portfolio that allows recruiters and possible employers to see my personality and talents as a UX/UI Designer without following the status-quo.",
  },
  {
    type: "p",
    text: "Product Goal",
  },
  {
    type: "u",
    text: "I want to create a product that will showcase my work in an unconventional way and catch recruiters’ attention.",
  },
  {
    type: "break",
    style: { padding: "10px" },
  },
  {
    type: "h2",
    text: "Design Process",
  },
  {
    type: "p",
    text: "I sought numerous sources of inspiration during the research and ideation process, especially those stemming from my childhood as the 90’s are a huge trend currently. My aim was to create a unique solution that was simple, efficient, and memorable to everyone who came across my page.",
  },
  {
    type: "p",
    text: "Much like the “iPad kids” of today, when I wasn’t drawing or reading sci-fi and fantasy books, I was playing on my Tamagotchi, Nintendo DS, or Gameboy Advance SP. I decided to build my portfolio based on one of these devices as a homage to the origin of my love of technology, and where it has brought me today as a product designer in the tech industry.",
  },
  {
    type: "p",
    text: "I ultimately selected the Gameboy Advance SP as my format as its single screen and setup was most similar to a conventional website.",
  },
  {
    type: "h3",
    text: "Visual Design",
  },
  {
    type: "p",
    text: "I sought to emulate the nostalgia of opening up a Gameboy and pressing start, and researched the Gameboy Advance’s original design. I created a digital replica of an opened console for the loading page, including the screen for the top portion and the control pad, buttons, and speaker for the bottom portion.",
  },
  {
    type: "img",
    img: G2,
  },
  {
    type: "p",
    text: "I decided to go with a retro font, Press Start 2P, for an added throwback feel. I used this font throughout the portfolio and created my logo, a yuzu, by overlaying some of the font’s characters together. This logo is displayed on the loading screen underneath my site’s title.",
  },
  {
    type: "img",
    img: G3,
  },
  {
    type: "h3",
    text: "Menu",
  },
  {
    type: "p",
    text: "I did research on what menu sections were most common on portfolios. Out of the 20 portfolios I visited, 90% of them included some form of menu titles including Home, Projects, About, and Contact. I decided to include Resume as another title on my menu, as it may not be clear to users where exactly it would be located.",
  },
  {
    type: "p",
    text: "I also created a hover state for the menu featuring my logo on each side of each menu item when a user’s mouse hovers over it, to mimic the Gameboy’s selection indicator when pressing the control pad. This was a fun way to further integrate my logo into my design and cement the nostalgic gaming experience into my portfolio.",
  },
  {
    type: "img",
    img: G4,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h3",
    text: "Breadcrumbs",
  },
  {
    type: "p",
    text: "I faced a challenge when it came to the format of my portfolio as my menu wasn’t typical, rather it had its own page to itself. I used peer interviews to figure out how to indicate breadcrumbs, or the users’ position on the site, while still staying true to my original vision. ",
  },
  {
    type: "p",
    text: "Based on the feedback, I reserved the top left corner of the screen to show what section of the site users were on, and the bottom left corner of the screen for a back button. This enables users to keep track of where they are on my portfolio and allow them to return to the previous section they were browsing.",
  },
  {
    type: "img",
    img: G5,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h2",
    text: "Results",
  },
  {
    type: "p",
    text: "Overall, this portfolio taught me a lot about designing for your intended user. Although it did land me a contract position as a Product Designer at a stealth startup, I soon realized it only catered to peers of the same generation, and probably wasn’t the best choice for recruiters and employers of different childhood experiences.",
  },
  {
    type: "p",
    text: "I learned that recruiters take about 5 minutes or less to view a portfolio and came to the conclusion that although it’s a very cool concept, it’s not a very good format when it comes to getting a quick overview of one’s work and experience. I admittedly fell into the trap of designing for myself, and not my users.",
  },
  {
    type: "p",
    text: "I’ve since redesigned my portfolio to one that’s more balanced - it follows a more conventional format for easy viewing but is still fun, interactive, and eye-catching. In the future, I would love to revisit this concept in a different type of project and allow users to play around and take their time to explore it.",
  },
  {
    type: "img",
    img: G6,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
];

export const Startup = [
  {
    type: "h2",
    text: "Developmint Co.",
  },
  {
    type: "h3",
    text: "Developmint Co. - A Stealth Startup Landing Page - Under NDA",
  },
  {
    type: "block",
    text: [
      "Team: Lauren Phillips",
      "Tools: Figma, Jira, Google Suite, Teams, Draw.io",
      "Timeline: January 15, 2022 - Present",
    ],
  },
  {
    type: "img",
    img: S1,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
  {
    type: "h2",
    text: "Project Overview",
  },
  {
    type: "p",
    text: "Developmint Co. is a Los Angeles-based stealth startup preparing for launch in the technology industry. This project is ongoing and currently under NDA.",
  },
  {
    type: "p",
    text: "I’m collaborating with stakeholders and developers on their official site and platforms as a part of Developmint’s product team. We’ve published a simple landing page which I designed to function as a placeholder that accurately and discreetly depicts the brand and services Developmint aims to provide. Our other projects are unavailable to the public until they are ready for launch, projected for Spring 2023.",
  },
  {
    type: "h2",
    text: "Problem Statement",
  },
  {
    type: "p",
    text: "Developmint’s stakeholders had a general idea of the feelings they wanted their company and website to elicit, however they didn’t know exactly how to execute their vision. I identified their wants and needs and developed a launch page that would provide a great starting point for their stealth startup.",
  },
  {
    type: "p",
    text: "Developmint needs a landing page that:",
  },
  {
    type: "ol",
    text: [
      "Gives minimal information and build up excitement for their launch",
      "Allows prospective employees to reach out and apply",
    ],
  },
  {
    type: "h2",
    text: "Goals",
  },
  {
    type: "p",
    text: "User Goals:",
  },
  {
    type: "u",
    text: "We want to provide a mobile and desktop landing page for prospective clients and employees that demonstrates Developmint’s endeavors and provides a time frame leading up to launch, while preserving the “stealth” aspect of the startup.",
  },
  {
    type: "p",
    text: "Business Goals:",
  },
  {
    type: "u",
    text: "We want to create an attractive and engaging preview of the business while preventing overexposure for Developmint. We also want to allow interested parties to contact the team as needed, measured by number of clicks and time spent on the site.",
  },
  {
    type: "break",
    style: { padding: "10px" },
  },
  {
    type: "h2",
    text: "Design Process",
  },
  {
    type: "p",
    text: "Although the stakeholders already had a vision of what they wanted from their site, I took the time to ideate as well. I came up with a list of adjectives that I would use to describe the business, along with different ways I could build buzz surrounding the brand and what they would eventually offer consumers.",
  },
  {
    type: "p",
    text: "While researching other stealth startup landing pages, I found that the most effective ones were the ones that kept it simple. Because of this, I decided to incorporate only a few elements into the site rather than overwhelm it with information.",
  },
  {
    type: "h3",
    text: "Countdown Timer",
  },
  {
    type: "p",
    text: "We wanted to build excitement while also giving information on when the launch was to be expected. ",
  },
  {
    type: "p",
    text: "After doing research, I found that a countdown timer would be a great way to visually depict how much more time there is until launch. It introduces movement to the page and adds a sense of expectation for users.",
  },
  {
    type: "img",
    img: S2,
    style: {
      width: "70%",
      padding: "20px 15% 20px 15%",
    },
  },
  {
    type: "h3",
    text: "Careers Button",
  },
  {
    type: "p",
    text: "The stakeholders also wanted a way for prospective job applicants to get in touch with them, while still keeping the mission of the company a secret.",
  },
  {
    type: "p",
    text: "I added a careers button that allows users to send an email directly to the team. That way, the stakeholders can vet candidates without posting a job description that gives too much information.",
  },
  {
    type: "h3",
    text: "Visual Design",
  },
  {
    type: "p",
    text: "During my initial meetings with stakeholders, they communicated to me that they would like their site to evoke a feeling of excitement for launch and a futuristic, glowing, polished vibe. ",
  },
  {
    type: "p",
    text: "I was given Developmint’s logo and worked with an artist to create the cityscape shown on the site. From these elements I was able to create a style guide for the landing page, using shadows and gradients to add to the glowing effect we wanted to convey.",
  },
  {
    type: "p",
    text: "I also wrote for the site, using the company’s name to create clever copy that was on-brand and memorable. After consulting our stakeholders and others within the company, we decided on the copy shown on the site today.",
  },
  {
    type: "img",
    img: S3,
  },
  {
    type: "h2",
    text: "Results",
  },
  {
    type: "p",
    text: "After implementing these decisions and launching the site, we now have a website to direct all traffic and inquiries regarding Developmint that builds up hype for their future projects. Although we’re not listed on any search engines yet, we’ve seen an increase in site visits as our stakeholders share our site with prospective clients and employees.",
  },
  {
    type: "p",
    text: "This site has contributed to Developmint’s budding brand image and allows users to look forward to our launch. I’m still working with the team on the official site launch that will replace the current design, which will provide users with a clear vision of Developmint Co. and its platforms once it’s finalized.",
  },
  {
    type: "img",
    img: S4,
    style: {
      width: "100%",
      padding: "20px 0 20px 0",
    },
  },
];

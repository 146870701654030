import React, { Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

class DrawerPopout extends Component {
  render() {
    return this.renderDrawer();
  }

  renderDrawer = () => {
    const { showDrawer, handleHideDrawer } = this.props;
    return (
      <Drawer anchor={"right"} open={showDrawer} onClose={handleHideDrawer}>
        {this.renderList()}
      </Drawer>
    );
  };

  renderList = () => {
    const { handleHideDrawer, handleDrawerItemClick } = this.props;
    return (
      <Box onClick={handleHideDrawer}>
        <List>
          {["about", "work", "play", "contact"].map((text, index) => (
            <ListItem key={text + "-" + index}>
              <ListItemButton onClick={() => handleDrawerItemClick(text)}>
                <ListItemText primary={text} sx={{ color: "#4a1d12" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };
}

export default DrawerPopout;

import React, { Component } from "react";
import { TableOfContents } from "./TableOfContent";
import CustomFieldObject from "./CustomFieldObject";
import {
  HoneyMoonHonolulu,
  Narika,
  Startup,
  Gameboy,
} from "../assets/data/ProjectInformation";
import "../assets/css/components/ProjectInformationPanel.css";

class ProjectInformation extends Component {
  render() {
    return this.renderDataPanel();
  }

  renderDataPanel = () => {
    const { renderLandscapeDataPanel, renderMobileDataPanel } = this;
    const { isMobile } = this.props;
    return (
      <div className="project-information-container">
        {isMobile ? renderMobileDataPanel() : renderLandscapeDataPanel()}
      </div>
    );
  };

  renderLandscapeDataPanel = () => {
    const { renderData, renderTableOfContents } = this;
    return (
      <React.Fragment>
        <main>{renderData()}</main>
        {renderTableOfContents()}
      </React.Fragment>
    );
  };

  renderMobileDataPanel = () => {
    const { renderData } = this;
    return <mobile-main>{renderData()}</mobile-main>;
  };

  renderData = () => {
    const { getProjectInformation } = this;

    return getProjectInformation().map((field, index) => {
      return (
        <CustomFieldObject
          key={"customField-" + index}
          field={field}
          index={index}
        />
      );
    });
  };

  renderTableOfContents = () => {
    return <TableOfContents />;
  };

  getProjectInformation = () => {
    const { id } = this.props;
    switch (id) {
      case "honeymoon":
        return HoneyMoonHonolulu;
      case "narika":
        return Narika;
      case "startup":
        return Startup;
      case "gameboy":
        return Gameboy;
      default:
        return [];
    }
  };
}

export default ProjectInformation;

import React, { Component } from "react";
import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import ProjectInformationPanel from "../components/ProjectInformationPanel";
import ScrollArrow from "../components/ScrollArrow";
import { H0, N0, S0, G0 } from "../exports/Images";
import "../assets/css/parts/ProjectPopup.css";

class ProjectPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { showDownArrow: true };
  }
  render() {
    return this.renderProjectPopup();
  }

  renderProjectPopup = () => {
    const { renderOverlay, renderContentContainer } = this;
    return (
      <React.Fragment>
        {renderOverlay()}
        {renderContentContainer()}
      </React.Fragment>
    );
  };

  renderOverlay = () => {
    const { resetSelectedID } = this.props;
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay"
        onClick={resetSelectedID}
      ></motion.div>
    );
  };

  renderContentContainer = () => {
    const { id } = this.props;
    const { renderPanel } = this;
    return (
      <motion.div className="card-content-container">
        <motion.div className="card-content" layoutId={`card-container-${id}`}>
          <motion.div className="content-container" animate>
            {renderPanel()}
          </motion.div>
        </motion.div>
      </motion.div>
    );
  };

  renderPanel = () => {
    return (
      <Box
        sx={{
          display: "flex",
          height: "90vh",
          width: "100%",
        }}
      >
        <div
          id="boxwrapper"
          style={{
            width: "100%",
            overflowY: "scroll",
            scrollBehavior: "smooth",
          }}
        >
          {this.renderImage()}
          {this.renderProjectInformationPanel()}
          {this.renderScrollArrow()}
        </div>
      </Box>
    );
  };

  renderImage = () => {
    return (
      <motion.div
        id="imageid"
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
        initial={{ opacity: 0, scale: 0.75 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          default: {
            duration: 0.1,
            ease: [0, 0.71, 0.2, 1.01],
          },
          scale: {
            type: "spring",
            damping: 5,
            stiffness: 50,
            restDelta: 0.001,
          },
        }}
      >
        <motion.img
          className="project-imageO"
          style={{
            maxHeight: "75%",
            maxWidth: "75%",
          }}
          layoutId={`project-image-${this.props.id}`}
          src={this.getProjectImage()}
          alt={""}
        />
      </motion.div>
    );
  };

  getProjectImage = () => {
    const { id } = this.props;
    switch (id) {
      case "honeymoon":
        return H0;
      case "narika":
        return N0;
      case "gameboy":
        return G0;
      case "startup":
        return S0;
      default:
        return "";
    }
  };

  renderProjectInformationPanel = () => {
    return (
      <div id="information">
        <ProjectInformationPanel
          id={this.props.id}
          isMobile={this.props.isMobile}
        />
      </div>
    );
  };

  renderScrollArrow = () => {
    const { handleScrollDown } = this;
    const { showDownArrow } = this.state;
    return showDownArrow ? (
      <ScrollArrow showDownArrow handleScrollDown={handleScrollDown} />
    ) : (
      ""
    );
  };

  componentDidMount() {
    var elem = document.getElementById("boxwrapper");
    elem.addEventListener("scroll", (e) => this.handleScroll(e));
  }

  handleScroll = () => {
    const { showDownArrow } = this.state;
    var boxwrapper = document.getElementById("boxwrapper");
    let windowOffset = boxwrapper.scrollTop;
    var elem = document.getElementById("imageid");
    if (windowOffset < elem.offsetTop) {
      if (!showDownArrow) this.setState({ showDownArrow: true });
    } else {
      if (showDownArrow) this.setState({ showDownArrow: false });
    }
  };

  handleScrollDown = () => {
    var elem = document.getElementById("information");
    elem.scrollIntoView();
  };
}

export default ProjectPopup;

import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Seed } from "../exports/Images";

export const SeedParticles = (props) => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const landscapeOptions = {
    fullScreen: { enable: false },
    background: {
      color: {
        value: "#fffdf7",
      },
    },
    detectRetina: true,
    fpsLimit: 30,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        repulse: {
          distance: 150,
          duration: 0.1,
        },
      },
    },
    particles: {
      collisions: {
        enable: true,
        mode: "bounce",
        overlap: false,
      },

      move: {
        bounce: true,
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 0.4,
        straight: true,
      },
      number: {
        value: 12,
      },
      rotate: {
        animation: { enable: true, speed: 2.5, decay: 0, sync: false },
        random: { enable: true, minimumValue: 0 },
        value: 0,
      },
      shape: {
        type: "image",
        image: {
          src: Seed,
        },
      },
      size: {
        value: props.deviceType === "isTablet" ? 20 : 25,
      },
    },
  };

  const mobileOptions = {
    fullScreen: { enable: false },
    background: {
      color: {
        value: "#fffdf7",
      },
    },
    detectRetina: true,
    fpsLimit: 30,
    particles: {
      number: {
        value: 12,
      },
      shape: {
        type: "image",
        image: {
          src: Seed,
        },
      },
      size: {
        value: 15,
      },
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: -1,
        height: "100%",
        width: "100%",
      }}
    >
      <Particles
        id="tsparticles"
        init={particlesInit}
        height="94vh"
        options={props.isMobile ? mobileOptions : landscapeOptions}
      />
    </div>
  );
};

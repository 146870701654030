import { Gameboy, Honeymoon, Narika, Startup } from "../../exports/Images";

export const projects = [
  {
    id: "honeymoon",
    category: "honeymoon",
    title: "Honeymoon",
    img: Honeymoon,
  },
  {
    id: "narika",
    category: "narika",
    title: "Narika",
    img: Narika,
  },
  {
    id: "startup",
    category: "startup",
    title: "Startup",
    img: Startup,
  },
  {
    id: "gameboy",
    category: "gameboy",
    title: "Gameboy",
    img: Gameboy,
  },
];

import React, { Component } from "react";
import { Grid } from "@mui/material";
import {
  PlayImage1,
  PlayImage2,
  PlayImage3,
  PlayImage4,
  PlayImage5,
  PlayImage6,
  PlayImage7,
  PlayImage8,
  PlayImage9,
  PlayImage10,
  PlayImage11,
  PlayImage12,
} from "../exports/Images";
import "../assets/css/pages/PlayPage.css";

class PlayPage extends Component {
  render() {
    return this.renderPlayPage();
  }

  renderPlayPage = () => {
    const { renderPlayHeader, renderImages } = this;
    return (
      <Grid container spacing={3}>
        {renderPlayHeader()}
        {renderImages()}
      </Grid>
    );
  };

  renderPlayHeader = () => {
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className="play-header"
      >
        <h2>play</h2>
      </Grid>
    );
  };

  renderImages = () => {
    const { renderImage } = this;
    const imageList = this.getImageList();

    return imageList.map((image, index) => (
      <Grid
        key={image.title + "_" + index}
        item
        xs={4}
        container
        justifyContent="center"
        alignItems="center"
      >
        {renderImage(image)}
      </Grid>
    ));
  };

  renderImage = (image) => {
    return (
      <img
        src={image.img}
        alt={image.title}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "30vmin",
          height: image.height || "40vmin",
        }}
      />
    );
  };

  getImageList = () => {
    return [
      {
        img: PlayImage1,
        title: "PlayImage1",
      },
      {
        img: PlayImage2,
        title: "PlayImage2",
      },
      {
        img: PlayImage3,
        title: "PlayImage3",
      },
      {
        img: PlayImage4,
        title: "PlayImage4",
      },
      {
        img: PlayImage5,
        title: "PlayImage5",
      },
      {
        img: PlayImage6,
        title: "PlayImage6",
        height: "42vmin",
      },
      {
        img: PlayImage7,
        title: "PlayImage7",
        width: "44vmin",
      },
      {
        img: PlayImage8,
        title: "PlayImage8",
      },
      {
        img: PlayImage9,
        title: "PlayImage9",
      },
      {
        img: PlayImage10,
        title: "PlayImage10",
      },
      {
        img: PlayImage11,
        title: "PlayImage11",
        height: "19vmin",
      },
      {
        img: PlayImage12,
        title: "PlayImage12",
        height: "42vmin",
      },
    ];
  };
}

export default PlayPage;

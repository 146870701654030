import React, { Component } from "react";
import { BigYuzu } from "../exports/Images";
import Footer from "../parts/Footer";
import Header from "../parts/Header";
import LandscapeBody from "../parts/LandscapeBody";
import { SeedParticles } from "../parts/SeedParticles";
import "../assets/css/pages/Landscape.css";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = { page: "homepage" };
  }
  render() {
    return (
      <React.Fragment>
        {this.renderParticlesBackground()}
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
        {this.renderYuzuInCorner()}
      </React.Fragment>
    );
  }

  renderParticlesBackground = () => {
    return <SeedParticles {...this.props} />;
  };

  renderHeader = () => {
    return <Header {...this.props} scrollToPage={this.scrollToPage} />;
  };

  renderBody = () => {
    return (
      <LandscapeBody
        {...this.props}
        page={this.state.page}
        clearPage={this.clearPage}
      />
    );
  };

  renderFooter = () => {
    return <Footer {...this.props} />;
  };

  renderYuzuInCorner = () => {
    return (
      <img
        alt="yuzuexperience"
        src={BigYuzu}
        style={{
          position: "fixed",
          width: "30vmin",
          bottom: 0,
          right: 0,
          zIndex: 1000,
        }}
      />
    );
  };

  clearPage = () => {
    if (this.state.page !== "") this.setState({ page: "" });
  };
  scrollToPage = (page) => {
    this.setState({ page });
  };
}

export default Mobile;

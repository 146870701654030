import React, { Component } from "react";

class CustomFieldObject extends Component {
  render() {
    return this.renderCustomField();
  }

  renderCustomField = () => {
    const {
      renderH2Header,
      renderH3Header,
      renderBreak,
      renderBlock,
      renderParagraph,
      renderUnderline,
      renderBold,
      renderItalic,
      renderList,
      renderCustom,
      renderImage,
    } = this;

    const { field, index } = this.props;
    switch (field.type) {
      case "h2":
        return renderH2Header(field, index);
      case "h3":
        return renderH3Header(field, index);
      case "break":
        return renderBreak(field, index);
      case "block":
        return renderBlock(field, index);
      case "p":
        return renderParagraph(field, index);
      case "u":
        return renderUnderline(field, index);
      case "b":
        return renderBold(field, index);
      case "i":
        return renderItalic(field, index);
      case "ol":
        return renderList(field, index);
      case "custom":
        return renderCustom(field, index);
      case "img":
        return renderImage(field, index);
      default:
        return "";
    }
  };

  renderH2Header = (field, index) => {
    return (
      <h2 id={"h2-" + index} key={"h2-" + index}>
        {field.text}
      </h2>
    );
  };

  renderH3Header = (field, index) => {
    return (
      <h3 id={"h3-" + index} key={"h3-" + index}>
        {field.text}
      </h3>
    );
  };

  renderParagraph = (field, index) => {
    return (
      <p id={"p-" + index} key={"p-" + index}>
        {field.text}
      </p>
    );
  };

  renderUnderline = (field, index) => {
    return (
      <u id={"u-" + index} key={"u-" + index}>
        {field.text}
      </u>
    );
  };

  renderBold = (field, index) => {
    return (
      <b id={"b-" + index} key={"b-" + index}>
        {field.text}
      </b>
    );
  };

  renderItalic = (field, index) => {
    return (
      <i id={"i-" + index} key={"i-" + index}>
        {field.text}
      </i>
    );
  };

  renderBreak = (field, index) => {
    return (
      <div id={"break-" + index} key={"break-" + index} style={field.style} />
    );
  };

  renderBlock = (field, index) => {
    return (
      <p id={"block-" + index} key={"block-" + index}>
        {field.text.map((text, listIndex) => (
          <React.Fragment key={"block-" + index + "-field-" + listIndex}>
            <b> {text}</b>
            <br />
          </React.Fragment>
        ))}
      </p>
    );
  };

  renderList = (field, index) => {
    return (
      <ol id={"ol-" + index} key={"ol-" + index}>
        {field.text.map((text, listIndex) => (
          <li id={"li-" + listIndex} key={"li-" + listIndex}>
            {text}
          </li>
        ))}
      </ol>
    );
  };

  renderCustom = (field, index) => {
    return (
      <p id={"block-" + index} key={"block-" + index}>
        {field.text.map((field, listIndex) => {
          switch (field.type) {
            case "b":
              return (
                <b key={"custom-" + index + "-b-" + listIndex}>{field.text}</b>
              );
            case "u":
              return (
                <u key={"custom-" + index + "-u-" + listIndex}>{field.text}</u>
              );
            case "i":
              return (
                <i key={"custom-" + index + "-i-" + listIndex}>{field.text}</i>
              );
            default:
              return (
                <React.Fragment
                  key={"custom-" + index + "-default-" + listIndex}
                >
                  {field.text}
                </React.Fragment>
              );
          }
        })}
      </p>
    );
  };

  renderImage = (field, index) => {
    return (
      <img
        id={"img-" + index}
        key={"img-" + index}
        style={field.style ? field.style : { width: "100%" }}
        alt=""
        src={field.img}
      />
    );
  };
}

export default CustomFieldObject;

import React, { Component } from "react";
import { Alert, Grid, Snackbar, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import emailjs from "@emailjs/browser";
import "../assets/css/pages/ContactPage.css";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showSnackbar: false, isSuccessfullySent: false };
    this.formRef = React.createRef();
  }

  render() {
    return this.renderContactMe();
  }

  renderContactMe = () => {
    const { renderLandscapeContactMe, renderMobileContactMe } = this;
    const { isMobile } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        style={{ minHeight: "30vmin", paddingRight: "2%" }}
      >
        {isMobile ? renderMobileContactMe() : renderLandscapeContactMe()}
      </Grid>
    );
  };

  renderLandscapeContactMe = () => {
    const { renderHeader, renderContactForm, renderSnackBar, renderMap } = this;
    return (
      <React.Fragment>
        {renderHeader()}
        {renderContactForm()}
        {renderMap()}
        {renderSnackBar()}
      </React.Fragment>
    );
  };

  renderMobileContactMe = () => {
    const {
      renderHeader,
      renderMobileContactForm,
      renderSnackBar,
      renderMobileMap,
    } = this;
    return (
      <React.Fragment>
        {renderHeader()}
        {renderMobileContactForm()}
        {renderMobileMap()}
        {renderSnackBar()}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className="contact-header"
      >
        <h2>reach out to me!</h2>
      </Grid>
    );
  };

  renderMobileContactForm = () => {
    const { renderField, renderFormSubmitButton } = this;
    return (
      <Grid item xs={12}>
        <form
          ref={this.formRef}
          onSubmit={this.submitMessage}
          style={{ width: "100%" }}
        >
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} style={{ width: "100%" }}>
              {renderField(
                "Your Email Address",
                "something@website.com",
                "user_email"
              )}
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {renderField(
                "Subject",
                "Question about your design process",
                "subject"
              )}
            </Grid>

            <Grid item xs={12} style={{ width: "100%" }}>
              {renderField(
                "Message Contents",
                "Your message starts with…",
                "message"
              )}
            </Grid>

            <Grid
              item
              xs={12}
              className="contact-button-container"
              style={{ width: "100%" }}
            >
              {renderFormSubmitButton()}
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };

  renderContactForm = () => {
    const { renderField, renderFormSubmitButton } = this;
    return (
      <Grid item xs={5}>
        <form
          ref={this.formRef}
          onSubmit={this.submitMessage}
          style={{ width: "100%" }}
        >
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            <Grid item xs={3} style={{ width: "100%" }}>
              {renderField(
                "Your Email Address",
                "something@website.com",
                "user_email"
              )}
            </Grid>
            <Grid item xs={2} style={{ width: "100%" }}>
              {renderField(
                "Subject",
                "Question about your design process",
                "subject"
              )}
            </Grid>

            <Grid item xs={2} style={{ width: "100%" }}>
              {renderField(
                "Message Contents",
                "Your message starts with…",
                "message",
                5
              )}
            </Grid>

            <Grid item xs={3} className="contact-button-container">
              {renderFormSubmitButton()}
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };

  renderField = (label, placeholder, fieldName, multiline) => {
    const CustomTextField = styled(TextField)({
      "& label.Mui-focused": {
        color: "#4a1d12",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#4a1d12",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#4a1d12",
        },
        "&:hover fieldset": {
          borderColor: "#4a1d12",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#4a1d12",
        },
      },
    });

    return (
      <CustomTextField
        name={fieldName}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        sx={{
          input: { color: "#4a1d12", fontSize: "2vmin" },
        }}
        style={{ width: "100%" }}
        multiline={multiline ? true : false}
        minRows={multiline ? multiline : 1}
        focused
        required={true}
      />
    );
  };

  renderFormSubmitButton = () => {
    return (
      <span
        className="contact-button"
        type="submit"
        value="SUBMIT"
        onClick={this.submitMessage}
        style={{ width: "100%" }}
      >
        send
      </span>
    );
  };

  renderSnackBar = () => {
    const { showSnackbar, isSuccessfullySent } = this.state;
    return (
      <Snackbar
        open={showSnackbar}
        onClose={() => this.setState({ showSnackbar: false })}
        autoHideDuration={10000}
      >
        {isSuccessfullySent ? (
          <Alert
            severity="success"
            style={{
              background: "white",
              color: "#4a1d12",
              border: "2px solid #e5d96a",
            }}
          >
            Thanks for reaching out, we'll be in touch shortly!
          </Alert>
        ) : (
          <Alert severity="error">Error Sending Message!</Alert>
        )}
      </Snackbar>
    );
  };

  renderMap = () => {
    return (
      <Grid item xs={5}>
        <div
          style={{
            height: "50vmin",
            width: "100%",
          }}
        >
          <iframe
            title="Los Angeles"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?q=Los%20Angeles,%20CA&t=&z=9&ie=UTF8&iwloc=&output=embed"
            scrolling="no"
            allowFullScreen="no"
          ></iframe>
        </div>
      </Grid>
    );
  };

  renderMobileMap = () => {
    return (
      <Grid item xs={12}>
        <div
          style={{
            height: "35vmin",
            width: "100%",
          }}
        >
          <iframe
            title="Los Angeles"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?q=Los%20Angeles,%20CA&t=&z=9&ie=UTF8&iwloc=&output=embed"
            scrolling="no"
            allowFullScreen="no"
          ></iframe>
        </div>
      </Grid>
    );
  };

  submitMessage = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3ji0ks9",
        "template_pcyy2gb",
        this.formRef.current,
        "user_GrARf536BEEzNvxwJ17aN"
      )
      .then(
        () => {
          this.setState({
            showSnackbar: true,
            isSuccessfullySent: true,
          });
        },
        () => {
          this.setState({
            showSnackbar: true,
            isSuccessfullySent: false,
          });
        }
      );
  };
}

export default ContactPage;

import { FmdGoodOutlined, MailOutlineRounded } from "@mui/icons-material";
import React, { Component } from "react";
import "../assets/css/parts/Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.renderFooter();
  }

  renderFooter = () => {
    return (
      <div className="footer">
        {this.renderWave()}
        {this.renderFooterText()}
      </div>
    );
  };

  renderWave = () => {
    return (
      <svg viewBox="0 0 500 25">
        <defs>
          <pattern
            id="Wave"
            x="0"
            y="10"
            width="100"
            height="40"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M0 25 0 6C20 9 38 11 55 7 72 4 87 4 100 6l0 19z"
              id="path4"
              fill="#e5d96a"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#Wave)" />
      </svg>
    );
  };

  renderFooterText = () => {
    const { isMobile } = this.props;
    return (
      <div className="footer-text-wrapper">
        <div className="footer-text">
          {isMobile ? "" : <MailOutlineRounded />}
          <span style={{ paddingLeft: ".5%" }}>yuzuexperience@gmail.com</span>
          <span style={{ paddingLeft: ".5%", paddingRight: ".5%" }}>|</span>
          {isMobile ? "" : <FmdGoodOutlined />}
          <span style={{ paddingLeft: ".5%" }}>Los Angeles, CA</span>
        </div>
        <div className="footer-text">
          <span>Copyright © 2022 yuzuexperience </span>
        </div>
      </div>
    );
  };
}

export default Footer;

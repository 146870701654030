import React, { Component } from "react";
import { Grid } from "@mui/material";
import { Sticker } from "../exports/Images";
import "../assets/css/pages/HomePage.css";

class HomePage extends Component {
  render() {
    return <React.Fragment>{this.renderLandingScreen()}</React.Fragment>;
  }

  renderLandingScreen = () => {
    const { isMobile } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: isMobile ? "85vh" : "100%",
          minHeight: "70vh",
        }}
      >
        <Grid
          container
          style={{ background: "", height: "100%" }}
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid>
            <Grid item xs={12} style={{ paddingTop: "7%" }} />
            <Grid item xs={12}>
              <span
                className={
                  isMobile
                    ? "homepage-mobile-header-title"
                    : "homepage-header-title"
                }
              >
                yu•zu ex•pe•ri•ence
              </span>
              <br />
              <span className="header-pronunciation">
                /ˈyo͞ozo͞o/ /ˌikˈspirēəns/
              </span>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "7%" }} />

            <Grid item xs={12} className="header-breakdown">
              1. A wordplay on user experience and yuzu, a Japanese citrus.{" "}
              <br />
              2. A portfolio by Lauren E. Phillips.
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <img
                src={Sticker}
                alt=""
                style={{
                  width: "17vmin",
                  paddingRight: "5%",
                  transform: "rotate(-10deg)",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
}

export default HomePage;

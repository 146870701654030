import React, { Component } from "react";
import { Grid } from "@mui/material";
import { AboutMePic } from "../exports/Images";
import "../assets/css/pages/AboutMePage.css";
import ResumePopup from "../parts/ResumePopup";
import Resume from "../assets/files/Lauren_Phillips'_Resume.pdf";

class AboutMePage extends Component {
  constructor(props) {
    super(props);
    this.state = { showResume: false };
  }

  render() {
    return this.renderAbout();
  }

  renderAbout = () => {
    const { renderMobileAbout, renderLandscapeAbout } = this;
    const { isMobile } = this.props;

    return isMobile ? renderMobileAbout() : renderLandscapeAbout();
  };

  renderLandscapeAbout = () => {
    return (
      <div className="aboutPageContainer">
        <Grid container justifyContent="space-between">
          {this.renderLeftPanel()}
          {this.renderRightPanel()}
          {this.renderResumeModal()}
        </Grid>
      </div>
    );
  };

  renderMobileAbout = () => {
    return (
      <div className="aboutPageContainer">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} container justifyContent="center">
            <img className="aboutme-picture" src={AboutMePic} alt="" />
          </Grid>
          <Grid item xs={12} container justifyContent="center" paddingTop={2}>
            <Grid item xs={10}>
              {this.renderSummaryHeader()}
            </Grid>
            <Grid item xs={10}>
              {this.renderSummary()}
            </Grid>
            <Grid item xs={10} className="aboutme-resume-button-container">
              {this.renderMobileResumeButton()}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderLeftPanel = () => {
    return (
      <Grid item xs={6} container alignContent="center" justifyContent="center">
        <img className="aboutme-picture" src={AboutMePic} alt="" />
      </Grid>
    );
  };

  renderRightPanel = () => {
    return (
      <Grid item xs={6} container alignContent="center" justifyContent="center">
        <Grid item xs={8}>
          {this.renderSummaryHeader()}
        </Grid>
        <Grid item xs={8}>
          {this.renderSummary()}
        </Grid>

        <Grid item xs={8} className="aboutme-resume-button-container">
          {this.renderResumeButton()}
        </Grid>
      </Grid>
    );
  };

  renderSummaryHeader = () => {
    return <h3 className="aboutme-header">ようこそ！</h3>;
  };

  renderSummary = () => {
    return (
      <React.Fragment>
        <p className="aboutme-body">
          My name is Lauren and I’m a UX/UI designer focused on multicultural
          analysis, human behaviors, and strategy.
        </p>
        <p className="aboutme-body">
          In my design process, I'm passionate about understanding problems,
          building connections, and translating user needs into meaningful
          solutions.
        </p>
        <p className="aboutme-body">
          I love the challenge of creating virtual environments that capture the
          essence of each individual client.
        </p>
      </React.Fragment>
    );
  };

  renderResumeButton = () => {
    const { showResumeModal } = this;
    return (
      <span className="aboutme-resume-button" onClick={showResumeModal}>
        view resume
      </span>
    );
  };

  renderMobileResumeButton = () => {
    return (
      <a
        className="aboutme-resume-button"
        href={Resume}
        target="_blank"
        rel="noreferrer"
      >
        view resume
      </a>
    );
  };

  renderResumeModal = () => {
    const { showResume } = this.state;
    const { hideResumeModal } = this;
    return showResume && <ResumePopup isMobile hideResume={hideResumeModal} />;
  };

  showResumeModal = () => {
    const { showPopup } = this.props;
    this.setState({ showResume: true });
    showPopup("");
  };

  hideResumeModal = () => {
    const { hidePopup } = this.props;
    this.setState({ showResume: false });
    hidePopup();
  };
}

export default AboutMePage;

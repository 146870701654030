import React, { Component } from "react";
import resume from "../assets/files/Lauren_Phillips'_Resume.pdf";
import "../assets/css/parts/ResumePopup.css";

class ProjectPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { showDownArrow: true };
  }
  render() {
    return this.renderProjectPopup();
  }

  renderProjectPopup = () => {
    const { renderOverlay, renderContentContainer } = this;
    return (
      <React.Fragment>
        {renderOverlay()}
        {renderContentContainer()}
      </React.Fragment>
    );
  };

  renderOverlay = () => {
    const { hideResume } = this.props;
    return (
      <div
        style={{ pointerEvents: "auto" }}
        className="resume-overlay"
        onClick={hideResume}
      />
    );
  };

  renderContentContainer = () => {
    const { renderPanel } = this;
    return (
      <div className="resume-content-container">
        <div className="resume-content">{renderPanel()}</div>
      </div>
    );
  };

  renderPanel = () => {
    return (
      <iframe
        src={`${resume}#view=fitH`}
        title="Lauren Phillips' Resume"
        height="100%"
        width="100%"
      />
    );
  };
}

export default ProjectPopup;

import React, { Component } from "react";
import { Grid, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerPopout from "../components/DrawerPopout";
import { Yuzu } from "../exports/Images";
import { motion } from "framer-motion";
import "../assets/css/parts/Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { showDrawer: false };
  }
  render() {
    return this.renderHeader();
  }

  renderHeader = () => {
    const { renderLandscapeHeader, renderMobileHeader } = this;
    const { isMobile } = this.props;
    return isMobile ? renderMobileHeader() : renderLandscapeHeader();
  };

  renderLandscapeHeader = () => {
    const { renderButton, renderYuzu } = this;
    return (
      <Grid
        container
        spacing={2}
        className="header"
        justifyContent="space-between"
      >
        <Grid item xs={1} />
        <Grid item xs={2}>
          {renderButton("about")}
        </Grid>
        <Grid item xs={2}>
          {renderButton("work")}
        </Grid>
        <Grid item xs={2}>
          {renderYuzu()}
        </Grid>
        <Grid item xs={2}>
          {renderButton("play")}
        </Grid>
        <Grid item xs={2}>
          {renderButton("contact")}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    );
  };

  renderMobileHeader = () => {
    const { renderYuzu, renderDrawer } = this;
    return (
      <Grid
        container
        className="header"
        sx={{ padding: "0 5% 0 5%" }}
        justifyContent="space-around"
      >
        <Grid item container justifyContent={"flex-start"} xs={2}>
          {renderYuzu()}
        </Grid>

        <Grid item container justifyContent={"center"} xs={8}>
          {/* <span className="mobile-header-title"> yu•zu ex•pe•ri•ence</span> */}
        </Grid>
        <Grid item container justifyContent={"flex-end"} xs={2}>
          {renderDrawer()}
        </Grid>
      </Grid>
    );
  };

  renderYuzu = () => {
    const { scrollToPage } = this.props;
    return (
      <motion.img
        className="header-yuzu"
        whileHover={{ rotate: [0, -15, 0, 15, 0, -15, 0] }}
        whileTap={{ rotate: [0, -15, 0, 15, 0, -15, 0] }}
        transition={{ duration: 0.5 }}
        alt=""
        src={Yuzu}
        onClick={() => scrollToPage("home")}
      />
    );
  };

  renderButton = (text) => {
    const { scrollToPage } = this.props;
    return (
      <span className="header-button" onClick={() => scrollToPage(text)}>
        {text}
      </span>
    );
  };

  renderDrawer = () => {
    const { showDrawer } = this.state;
    const { handleHideDrawer, handleDrawerItemClick } = this;

    return (
      <React.Fragment>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          onClick={() => this.setState({ showDrawer: true })}
        >
          <MenuIcon />
        </IconButton>
        <DrawerPopout
          showDrawer={showDrawer}
          handleHideDrawer={handleHideDrawer}
          handleDrawerItemClick={handleDrawerItemClick}
        />
      </React.Fragment>
    );
  };

  handleDrawerItemClick = (text) => {
    const { scrollToPage } = this.props;
    scrollToPage(text);
    this.setState({ showDrawer: false });
  };

  handleHideDrawer = () => {
    this.setState({ showDrawer: false });
  };
}

export default Header;

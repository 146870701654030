import React, { Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Carousel from "react-material-ui-carousel";
import { projects } from "../assets/data/ProjectsList";
import ProjectPopup from "../parts/ProjectPopup";
import "../assets/css/pages/WorkPage.css";

class ProjectsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedId: "" };
  }

  render() {
    const { renderWorkPage } = this;
    return renderWorkPage();
  }

  renderWorkPage = () => {
    const { renderProjects, renderProjectPopup } = this;
    return (
      <React.Fragment>
        {renderProjects()}
        {renderProjectPopup()}
      </React.Fragment>
    );
  };

  renderProjectPopup = () => {
    const { selectedId, resetSelectedID, isMobile } = this.props;
    return (
      <AnimatePresence>
        {selectedId && (
          <ProjectPopup
            id={selectedId}
            resetSelectedID={resetSelectedID}
            isMobile={isMobile}
          />
        )}
      </AnimatePresence>
    );
  };

  renderProjects = () => {
    const { renderProject } = this;
    return (
      <Carousel
        className="projects-container"
        animation="slide"
        autoPlay={false}
        duration="500"
        height="70vh"
        navButtonsAlwaysVisible
      >
        {projects.map((item, index) => {
          return renderProject(item, index);
        })}
      </Carousel>
    );
  };

  renderProject = (item, index) => {
    const { updateSelectedID } = this.props;
    const { img, title, id } = item;
    return (
      <motion.div
        layoutId={`card-container-${id}`}
        key={index}
        style={{
          height: "100%",
        }}
      >
        <motion.div
          layoutId={`card-image-container-${id}`}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <motion.img
            layoutId={`project-image-${id}`}
            src={img}
            alt={title}
            style={{
              background: "",
              cursor: "pointer",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            onClick={() => updateSelectedID(id)}
          />
        </motion.div>
      </motion.div>
    );
  };
}

export default ProjectsPage;

import React, { Component } from "react";
import { Grid } from "@mui/material";
import {
  AboutMePage,
  ContactPage,
  HomePage,
  PlayPage,
  WorkPage,
} from "../exports/Pages";
import "../assets/css/parts/Body.css";
import ScrollArrow from "../components/ScrollArrow";

class LandscapeBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: "",
      showDownArrow: true,
      showRefArrow: true,
    };
    this.aboutRef = React.createRef();
    this.workRef = React.createRef();
    this.homeRef = React.createRef();
    this.playRef = React.createRef();
    this.contactRef = React.createRef();
  }

  render() {
    const {
      renderAboutMePage,
      renderContactPage,
      renderHomePage,
      renderPlayPage,
      renderWorkPage,
      renderScrollArrow,
      homeRef,
      aboutRef,
      workRef,
      playRef,
      contactRef,
    } = this;

    const { showRefArrow } = this.state;

    return (
      <div className="bodyContainer">
        <Grid container spacing={2}>
          <Grid ref={homeRef} item xs={12}>
            {renderHomePage()}
          </Grid>
          <Grid
            ref={aboutRef}
            item
            xs={12}
            style={{ paddingTop: "20vh", background: "", paddingBottom: "5vh" }}
          >
            {renderAboutMePage()}
          </Grid>
          <Grid
            ref={workRef}
            item
            xs={12}
            style={{
              paddingTop: "15vh",
              background: "",
              paddingBottom: "5vh",
            }}
          >
            {renderWorkPage()}
          </Grid>
          <Grid
            ref={playRef}
            item
            xs={12}
            style={{ paddingTop: "10vh", background: "", paddingBottom: "5vh" }}
          >
            {renderPlayPage()}
          </Grid>
          <Grid
            ref={contactRef}
            item
            xs={12}
            style={{ paddingTop: "10vh", background: "" }}
          >
            {renderContactPage()}
          </Grid>
        </Grid>
        {showRefArrow ? renderScrollArrow() : ""}
      </div>
    );
  }

  renderAboutMePage = () => {
    const { showPopup, hidePopup } = this;

    return (
      <AboutMePage
        {...this.props}
        hidePopup={hidePopup}
        showPopup={showPopup}
      />
    );
  };

  renderContactPage = () => {
    return <ContactPage {...this.props} />;
  };

  renderHomePage = () => {
    return <HomePage {...this.props} />;
  };

  renderPlayPage = () => {
    return <PlayPage {...this.props} />;
  };

  renderWorkPage = () => {
    const { selectedId } = this.state;
    const { showPopup, hidePopup } = this;

    return (
      <WorkPage
        selectedId={selectedId}
        resetSelectedID={hidePopup}
        updateSelectedID={showPopup}
        {...this.props}
      />
    );
  };

  renderScrollArrow = () => {
    const { showDownArrow } = this.state;
    const { handleScrollDown, handleScrollUp } = this;

    return (
      <ScrollArrow
        showDownArrow={showDownArrow}
        handleScrollDown={handleScrollDown}
        handleScrollUp={handleScrollUp}
      />
    );
  };

  componentDidMount() {
    window.addEventListener("scroll", (e) => this.handleScroll(e));
  }

  componentDidUpdate = () => {
    const { page } = this.props;

    switch (page) {
      case "about":
        window.scrollTo(0, this.aboutRef.current.offsetTop);
        break;
      case "work":
        window.scrollTo(0, this.workRef.current.offsetTop);
        break;
      case "home":
        window.scrollTo(0, this.homeRef.current);
        break;
      case "play":
        window.scrollTo(0, this.playRef.current.offsetTop);
        break;
      case "contact":
        window.scrollTo(0, this.contactRef.current.offsetTop);
        break;
      default:
        break;
    }
    this.props.clearPage();
  };

  handleScroll = (e) => {
    const { showDownArrow } = this.state;
    let windowOffset = window.scrollY;
    this.setState({ currentLocation: windowOffset });

    if (windowOffset < this.contactRef.current.offsetTop) {
      if (!showDownArrow) this.setState({ showDownArrow: true });
    } else {
      if (showDownArrow) this.setState({ showDownArrow: false });
    }
  };

  handleScrollUp = () => {
    window.scrollTo(0, this.homeRef.current);
  };

  handleScrollDown = () => {
    let windowOffset = window.scrollY + 5;
    let aboutOffsetTop = this.aboutRef.current.offsetTop;
    let workOffsetTop = this.workRef.current.offsetTop;
    let playOffsetTop = this.playRef.current.offsetTop;
    let contactOffsetTop = this.contactRef.current.offsetTop;

    if (windowOffset < aboutOffsetTop) {
      window.scrollTo(0, aboutOffsetTop);
    } else if (windowOffset < workOffsetTop) {
      window.scrollTo(0, workOffsetTop);
    } else if (windowOffset < playOffsetTop) {
      window.scrollTo(0, playOffsetTop);
    } else if (windowOffset < contactOffsetTop) {
      window.scrollTo(0, contactOffsetTop);
    }
  };

  hidePopup = () => {
    document.body.style.overflow = "unset";
    this.setState({ selectedId: "", showRefArrow: true });
  };

  showPopup = (selectedId) => {
    document.body.style.overflow = "hidden";
    this.setState({ selectedId, showRefArrow: false });
  };
}

export default LandscapeBody;

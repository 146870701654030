import React, { Component } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "../assets/css/components/ScrollArrow.css";

class ScrollArrow extends Component {
  render() {
    return this.renderScrollArrow();
  }

  renderScrollArrow = () => {
    const { showDownArrow } = this.props;
    const { renderDownArrow, renderUpArrow } = this;
    return (
      <div className="scroll-arrow-container">
        {showDownArrow ? renderDownArrow() : renderUpArrow()}
      </div>
    );
  };

  renderDownArrow = () => {
    const { handleScrollDown } = this.props;
    return (
      <KeyboardArrowDown className="scroll-arrow" onClick={handleScrollDown} />
    );
  };

  renderUpArrow = () => {
    const { handleScrollUp } = this.props;
    return (
      <KeyboardArrowUp className="scroll-arrow" onClick={handleScrollUp} />
    );
  };
}

export default ScrollArrow;

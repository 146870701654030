import React from "react";
import { useEffect, useState, useRef } from "react";
import "../assets/css/components/TableOfContent.css";

const useHeadsObserver = () => {
  const [activeId, setActiveId] = useState("");
  const observer = useRef();

  useEffect(() => {
    const handleObsever = (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: "0px 0px -65% 0px",
      threshold: 0.9,
    });

    const elements = document.querySelectorAll("h2");
    elements.forEach((elem) => observer.current.observe(elem));
    return () => observer.current?.disconnect();
  }, []);

  return { activeId };
};

export const TableOfContents = () => {
  const [headings, setHeadings] = useState([]);
  const { activeId } = useHeadsObserver();

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("main h2")).map(
      (elem) => ({
        id: elem.id,
        text: elem.innerText,
      })
    );
    setHeadings(elements);
  }, []);

  return (
    <nav>
      <ul>
        {headings.map((heading, index) => (
          <li
            key={heading.id + "-" + index}
            className={heading.id === activeId ? "active" : ""}
          >
            <a
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`).scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// export default TableOfContent;
